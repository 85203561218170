<template>
    <div class="Client" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form class="pform" @submit.native.prevent :inline="true" v-model="form" size="medium">
                <el-form-item label="机构组" label-width="100px">
                    <el-select
                        v-model="form.deptGroupCode"
                        @change="handleChangeGroup"
                        filterable
                        :loading="loadingDeptGroupFlag"
                    >
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="group in meta.groups"
                            :value="group.code"
                            :label="group.name"
                            :key="group.code"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="机构名称" label-width="100px">
                    <el-select v-model="form.deptCode" @change="handleQueryByCondition" filterable>
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="dept in meta.deptCodes"
                            :label="dept.name"
                            :value="dept.code"
                            :key="dept.code"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="客户端状态" label-width="100px">
                    <el-select placeholder="客户端状态" v-model="form.status" @change="handleQueryByCondition">
                        <el-option label="请选择" value="" />
                        <el-option label="在线" value="1" />
                        <el-option label="离线" value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item label="物理状态" label-width="100px">
                    <el-select placeholder="物理状态" v-model="form.screenStatus" @change="handleQueryByCondition">
                        <el-option label="全部" value="" />
                        <el-option label="良好" value="1" />
                        <el-option label="异常" value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item label="设置屏幕状态" label-width="100px">
                    <el-select placeholder="设置屏幕状态" v-model="form.displayStatus" @change="handleQueryByCondition">
                        <el-option label="请选择" value="" />
                        <el-option label="扩展" value="1" />
                        <el-option label="非扩展" value="0" />
                    </el-select>
                </el-form-item>
            </el-form>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="660"
            >
                <el-table-column label="序号" width="60" type="index" align="center" fixed="left" />
                <el-table-column label="机构组" width="180" prop="deptGroupName"></el-table-column>
                <el-table-column label="机构" width="200" prop="deptName" sortable :sort-method="sortDeptName" />
                <el-table-column label="客户端编码" width="200" prop="code" />
                <el-table-column
                    label="识别设备类型"
                    width="120"
                    prop="systemInfo"
                    key="systemInfo"
                    sortable
                    :sort-method="sortSystemInfo"
                >
                </el-table-column>
                <el-table-column label="客户端版本" width="100" prop="clientVersion" />
                <el-table-column label="客户端状态" width="100" prop="status" align="center">
                    <template slot-scope="scope">
                        <span v-if="1 == scope.row.status" class="clr-green">在线</span>
                        <span v-else>离线</span>
                    </template>
                </el-table-column>
                <el-table-column label="物理双屏状态" prop="screenStatus" width="120">
                    <template slot-scope="scope">
                        <span v-if="1 == scope.row.screenStatus" class="clr-green">良好</span>
                        <span v-else-if="0 == scope.row.screenStatus" class="clr-pink">异常</span>
                    </template>
                </el-table-column>
                <el-table-column label="物理双屏状态获取时间" prop="lastScreenTime"></el-table-column>
                <el-table-column label="设置屏幕状态" prop="displayStatus">
                    <template slot-scope="scope">
                        <span v-if="1 == scope.row['displayStatus']" class="clr-green">扩展</span>
                        <span v-else-if="0 == scope.row['displayStatus']" class="clr-pink">非扩展</span>
                    </template>
                </el-table-column>
                <el-table-column label="登录员工姓名" prop="staffName"></el-table-column>
                <el-table-column label="员工手机号" prop="mobile"></el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page.sync="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    components: { CheckTableShowColumnDialog },

    mixins: [CheckTableShowColumn],

    name: 'Client',
    data() {
        return {
            form: {
                groupCode: '',
                deptCode: '',
                status: '', // 0 离线 1 在线
                screenStatus: '', // 0 异常单屏 1 正常双屏
                displayStatus: '', // 0 异常显示 1 正常扩展
                page: 1,
                limit: Util.Limit,
            },
            meta: {
                deptCodes: [],
                groups: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [
                {
                    groupName: '',
                    deptName: '',
                    code: '',
                    clientType: '',
                    version: '',
                    status: '',
                    screenStatus: '',
                    lastScreenTime: '',
                    displayStatus: '',
                    staffName: '',
                    mobile: '',
                },
            ],
            loadingDeptGroupFlag: true,
            url: {
                page: '/biz/clientReg/clientStatus',
                queryDeptByGroup: '/system/dept/deptTree',
                queryGroups: '/system/deptGroup/list',
            },
        };
    },

    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
        this.handleQueryByCondition();
        this.timer = setInterval(() => {
            this.handleQuery();
        }, 30000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    filters: {
        clientType(clientType) {
            if (typeof clientType == 'undefined') {
                return '';
            }
            if (clientType == '0') {
                return 'Win7 x64';
            }
            if (clientType == '1') {
                return 'Win7 x32';
            }
            if (clientType == '2') {
                return 'Xp';
            }
            if (clientType == '3') {
                return '安卓';
            }
            if (clientType == '4') {
                return '安卓APP';
            }
            if (clientType == '5') {
                return 'IOSAPP';
            }
        },

        screenStatus(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '异常';
            }
            if (status == '1') {
                return '良好';
            }
        },
        displayStatus(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '非扩展';
            }
            if (status == '1') {
                return '扩展';
            }
        },
    },
    methods: {
        handleChangeGroup(value) {
            const _this = this;
            this.form.deptCode = '';
            if (_this.meta.deptCodes[value]) {
                return _this.meta.deptCodes[value];
            }
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + value, (rst) => {
                _this.meta.deptCodes = rst;
            });
            this.handleQueryByCondition();
        },
        handleQueryByCondition() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTableFlag(_this, _this.url.page, _params, false);
        },
        sort(a, b) {
            if (Util.isEmpty(a)) {
                return -1;
            } else if (Util.isEmpty(b)) {
                return 1;
            } else {
                return String(a).localeCompare(String(b), 'zh');
            }
        },
        sortDeptName(a, b) {
            return this.sort(a.deptName, b.deptName);
        },
        sortClientType(a, b) {
            return this.sort(a.clientType, b.clientType);
        },
        sortSystemInfo(a, b) {
            return this.sort(a.systemInfo, b.systemInfo);
        },
        sortDeptGroupName(a, b) {
            return this.sort(a.deptGroupName, b.deptGroupName);
        },
    },
};
</script>
